<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('adr.title') }}</h3>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <v-btn color="success" @click="addAdr" small> <v-icon dark>fa-plus</v-icon>{{ $t('all.add') }} </v-btn>
    <br /><br />
    <v-card>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-left">{{ $t('adr.name') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="item.login" @click="editAdr(item)">
            <td>{{ item.name }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteAdr"
    ></modal_edit>
    <modal_delete v-if="modal_delete" v-bind:view="modalView" v-on:close="modalDelClose"></modal_delete>
  </div>
</template>

<script>
import modal_edit from './adr_edit.vue'
import modal_delete from './adr_delete.vue'

export default {
  data() {
    return {
      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
    }
  },
  components: {
    modal_edit,
    modal_delete,
  },
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    modalDelClose: function(prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'adr').then(
        response => {
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    addAdr: function() {
      this.modal = true
      this.modalView = []
    },
    editAdr: function(view) {
      this.modal = true
      this.modalView = view
    },
    deleteAdr: function(view) {
      this.modal_delete = true
      this.modalView = view
    },
  },
}
</script>

<style></style>
