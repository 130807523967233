<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="700">
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('adr.edittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!view.id">
        {{ $t('adr.addtitle') }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
            v-model="view.name"
            :label="$t('adr.name')"
            required
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" v-if="btnDel" flat @click.native="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['title', 'view'],
  data() {
    return {
      dialog: true,
      valid: false,
      btnDel: false,
    }
  },
  computed: {},
  mounted: function () {
    this.btnDel = this.view.id ? true : false
  },
  methods: {
    del: function () {
      this.$emit('delete', this.view)
    },
    save: function () {
      var t = this
      var arr = Object.assign({}, this.view)
      if (this.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'adr/save/', {
            adr: arr,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
